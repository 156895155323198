<template lang="">
        <CustomerList />
</template>
<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';

export default {
    name: "musteriler",
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            { title: 'Müşteriler', route: '/musteriler' },
        ]);
    },
    components:{
        CustomerList: () =>import('@/components/customers/CustomerList.vue')
    }
}
</script>
<style lang="">
    
</style>